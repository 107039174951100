import React, { useEffect, useState } from "react";
import "./App.css";
import "./fonts.css";
import { PageRoutes } from "./pages/PageRoutes";
import { Navbar } from "./components/sections/Navbar/Navbar";
import { Footer } from "./components/sections/Footer/Footer";
import { HelmetProvider } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { I18nextProvider } from 'react-i18next';
import { ToTopButton } from "./components/ToTopButton/ToTopButton";
import i18n from './utils/118n';

function App() {
    const [showNavbarAndFooter, setShowNavbarAndFooter] = useState<boolean>(true);
    const location = useLocation();

    const hiddenRoutes = [
        "/wedding/gor-arevik",
        "/wedding/gor-arevik/",
        "/templates/1001/",
        "/templates/1001",
        "/templates/1002/",
        "/templates/1002",
        "/templates/1003/",
        "/templates/1003",
        "/templates/1004/",
        "/templates/1004",
        "/templates/1005/",
        "/templates/1005",
        "/templates/1006",
        "/templates/1006/",
        "/templates/1007/",
        "/templates/1007",
        "/templates/1008/",
        "/templates/1008",
        "/templates/1009/",
        "/templates/1009",
        "/templates/1010",
        "/templates/1010/",
        "/templates/1011",
        "/templates/1011/",
        "/templates/1012",
        "/templates/1012/",
        "/templates/1013",
        "/templates/1013/",
        "/templates/1014",
        "/templates/1014/",
        "/templates/1015",
        "/templates/1015/",
        "/templates/1016",
        "/templates/1016/",
        "/templates/1017",
        "/templates/1017/",
        "/templates/1018",
        "/templates/1018/",
        "/templates/1019",
        "/templates/1019/",
        "/templates/1020",
        "/templates/1020/",
        "/templates/1021",
        "/templates/1021/",
        // =====Test Route for Form=====
        "/templates/test-private",
        "/templates/test-private/",
        // =====Test Route for Form=====

        "/wedding/ruben-sofi",
        "/wedding/ruben-sofi/",
        "/wedding/harutyun-amalya",
        "/wedding/harutyun-amalya/",
        "/wedding/hayk-mariam/",
        "/wedding/hayk-mariam",
        "/wedding/garo-palig",
        "/wedding/garo-palig/",
        "/wedding/karen-ani",
        "/wedding/karen-ani/",
        "/wedding/shavarsh-ev-aida",
        "/wedding/shavarsh-ev-aida/",
        "/wedding/sargis-ev-piruz",
        "/wedding/sargis-ev-piruz/",
        "/wedding/sergey-mariam",
        "/wedding/sergey-mariam/",
        "/wedding/rafo-syuzi",
        "/wedding/rafo-syuzi/",
        "/wedding/tigran-anahit",
        "/wedding/tigran-anahit/",
        "/wedding/yuri-elizabeth",
        "/wedding/yuri-elizabeth/",
        "/engagement/vram-aghavni",
        "/engagement/vram-aghavni/",
        "/wedding/tigran-ina",
        "/wedding/tigran-ina/",
        "/wedding/karen-sirusho",
        "/wedding/karen-sirusho/",
        "/wedding/mihran-rima",
        "/wedding/mihran-rima/",
        "/wedding/john-nelly",
        "/wedding/john-nelly/",
        "/wedding/tigran-sona",
        "/wedding/tigran-sona/",
        "/wedding/serzh-yeghegni",
        "/wedding/serzh-yeghegni/",
        "/wedding/artsruni-meri",
        "/wedding/artsruni-meri/",
        "/wedding/hrach-ev-ani",
        "/wedding/hrach-ev-ani/",
        "/wedding/grigor-veronica",
        "/wedding/grigor-veronica/",
        "/wedding/babken-christina",
        "/wedding/babken-christina/",
        "/wedding/samvel-mery",
        "/wedding/samvel-mery/",
        "/wedding/andranik-elina",
        "/wedding/andranik-elina/",
        "/mkrtutyun/emily",
        "/mkrtutyun/emily/",
        "/event/alluria",
        "/event/alluria/",
        "/wedding/karen-gayane",
        "/wedding/karen-gayane/",
        "/wedding/erik-syuzan",
        "/wedding/erik-syuzan/",
        "/wedding/azat-tamara",
        "/wedding/azat-tamara/",
        "/wedding/karo-manik",
        "/wedding/karo-manik/",
        "/wedding/arsen-luiza",
        "/wedding/arsen-luiza/",
        // "/wedding/demo032",
        // "/wedding/demo032/",
        "/wedding/erik-amalya",
        "/wedding/erik-amalya/",
        "/wedding/alen-lia",
        "/wedding/alen-lia/",
        "/wedding/khachatur-astghik",
        "/wedding/khachatur-astghik/",
        "/wedding/hoso-maga",
        "/wedding/hoso-maga/",
        "/mkrtutyun-taredardz/harutyunyanner",
        "/mkrtutyun-taredardz/harutyunyanner/",
        "/wedding/nerses-manush",
        "/wedding/nerses-manush/",
        "/wedding/qajo-anjela",
        "/wedding/qajo-anjela/",
        "/wedding/smbat-silvi",
        "/wedding/smbat-silvi/",
        "/wedding/artyom-aghunik",
        "/wedding/artyom-aghunik/",
        "/wedding/arman-shushanna",
        "/wedding/arman-shushanna/",
        "/mkrtutyun/gabrelianner-melkonianner",
        "/mkrtutyun/gabrelianner-melkonianner/",
        "/wedding/karen-susanna",
        "/wedding/karen-susanna/",
        "/wedding/roland-ani",
        "/wedding/roland-ani/",
        "/wedding/narek-susanna",
        "/wedding/narek-susanna/",
        "/wedding/andreas-liana",
        "/wedding/andreas-liana/",
        "/wedding/smbat-tatev",
        "/wedding/smbat-tatev/",
        "/wedding/eric-elina",
        "/wedding/eric-elina/",
        "/wedding/mikael-marina",
        "/wedding/mikael-marina/",
        "/wedding/erik-tsovinar",
        "/wedding/erik-tsovinar/",
        "/wedding/ishkhan-anna",
        "/wedding/ishkhan-anna/",
        "/wedding/edgar-sofya",
        "/wedding/edgar-sofya/",
        "/wedding/aram-amelie",
        "/wedding/aram-amelie/",
        "/wedding/hovsep-gayane",
        "/wedding/hovsep-gayane/",
        "/wedding/hayk-anna",
        "/wedding/hayk-anna/",
        "/wedding/levon-diana",
        "/wedding/levon-diana/",
        "/wedding/hovhannes-haykuhi",
        "/wedding/hovhannes-haykuhi/",
        "/wedding/edgar-hasmik",
        "/wedding/edgar-hasmik/",
        "/wedding/davit-diana",
        "/wedding/davit-diana/",
        "/wedding/vladimir-rima",
        "/wedding/vladimir-rima/",
        "/wedding/harout-jessica",
        "/wedding/harout-jessica/",
        "/wedding/harout-jessica/en",
        "/wedding/harout-jessica/en/",
        "/wedding/sergey-anahit",
        "/wedding/sergey-anahit/",
        "/wedding/vahe-armine",
        "/wedding/vahe-armine/",
        "/wedding/rafayel-hasmik",
        "/wedding/rafayel-hasmik/",
        "/wedding/mihran-masha",
        "/wedding/mihran-masha/",
        "/wedding/gevorg-monika",
        "/wedding/gevorg-monika/",
        "/wedding/khachatur-diana",
        "/wedding/khachatur-diana/",
        "/wedding/gnel-zhenya",
        "/wedding/gnel-zhenya/",
        "/wedding/gagik-syuzanna",
        "/wedding/gagik-syuzanna/",
        "/wedding/gegham-lusine",
        "/wedding/gegham-lusine/",
        "/wedding/edgar-klara",
        "/wedding/edgar-klara/",
        "/wedding/arsen-karine",
        "/wedding/arsen-karine/",
        "/wedding/volodya-galina",
        "/wedding/volodya-galina/",
        "/wedding/shant-salpy",
        "/wedding/shant-salpy/",
        "/wedding/aram-christina",
        "/wedding/aram-christina/",
        "/wedding/hayk-nadya",
        "/wedding/hayk-nadya/",
        "/wedding/melqon-syuzanna",
        "/wedding/melqon-syuzanna/",
        "/wedding/armen-donara/",
        "/wedding/armen-donara",
        "/wedding/makich-monika",
        "/wedding/makich-monika/",
        "/wedding/gevorg-ashkhen/",
        "/wedding/gevorg-ashkhen",
        "/wedding/ashot-yana/",
        "/wedding/ashot-yana",
        "/wedding/ashot-yana/en/",
        "/wedding/ashot-yana/en",
        "/wedding/hayk-sona",
        "/wedding/hayk-sona/",
        "/wedding/gor-ev-elen",
        "/wedding/gor-ev-elen/",
        "/wedding/hayk-luiza",
        "/wedding/hayk-luiza/",
        "/wedding/karen-diana",
        "/wedding/karen-diana/",
        "/wedding/hoso-karine",
        "/wedding/hoso-karine/",
        "/tooth-party/demo089",
        "/tooth-party/demo089/",
        "/engagement/samvel-ofelya",
        "/engagement/samvel-ofelya/",
        // "/wedding/petros-lilit",
        // "/wedding/petros-lilit/",
        "/wedding/sos-sirun",
        "/wedding/sos-sirun/",
        "/wedding/armen-diana",
        "/wedding/armen-diana/",
        "/wedding/david-luiza",
        "/wedding/david-luiza/",
        "/wedding/vardan-lyuba",
        "/wedding/vardan-lyuba/",
        "/wedding/david-liana",
        "/wedding/david-liana/",
        "/wedding/aghvan-michelle",
        "/wedding/aghvan-michelle/",
        "/wedding/artur-nane",
        "/wedding/artur-nane/",
        "/wedding/artur-nane/ru",
        "/wedding/artur-nane/ru/",
        "/wedding/gagik-meri",
        "/wedding/gagik-meri/",
        "/wedding/rubik-amalya",
        "/wedding/rubik-amalya/",
        "/wedding/sergey-silvia",
        "/wedding/sergey-silvia/",
        "/wedding/sergey-silvia/ru",
        "/wedding/sergey-silvia/ru/",
        "/wedding/albert-sofya",
        "/wedding/albert-sofya/",
        "/wedding/georgi-anna",
        "/wedding/georgi-anna/",
        "/wedding/arman-nelly",
        "/wedding/arman-nelly/",
        "/wedding/arman-lilya",
        "/wedding/arman-lilya/",
        "/wedding/tadevos-anna",
        "/wedding/tadevos-anna/",
        "/wedding/tadevos-anna/en",
        "/wedding/tadevos-anna/en/",
        "/wedding/mels-ruzanna",
        "/wedding/mels-ruzanna/",
        "/wedding/mels-ruzanna/en",
        "/wedding/mels-ruzanna/en",
        "/wedding/volodya-maria",
        "/wedding/volodya-maria/",
        "/wedding/vigen-ani",
        "/wedding/vigen-ani",
        "/wedding/vaso-maria",
        "/wedding/vaso-maria",
        "/wedding/gagik-srbuhi",
        "/wedding/gagik-srbuhi/",
        "/wedding/hovhannes-varduhi",
        "/wedding/hovhannes-varduhi/",
        "/wedding/armen-sona",
        "/wedding/armen-sona/",
        "/wedding/edgar-evelina",
        "/wedding/edgar-evelina/",
        "/wedding/gevorg-naira",
        "/wedding/gevorg-naira/",
        "/wedding/robert-elen",
        "/wedding/robert-elen/",
        "/wedding/gewedding",
        "/wedding/gewedding/",
        "/wedding/edmond-dayana",
        "/wedding/edmond-dayana/",
        "/wedding/suren-ariana",
        "/wedding/suren-ariana/",
        "/wedding/demo120",
        "/wedding/demo120/",
        "/wedding/gevorg-mery",
        "/wedding/gevorg-mery/",
        "/wedding/sargis-anushik",
        "/wedding/sargis-anushik/",
        "/wedding/nubar-seda",
        "/wedding/nubar-seda/",
        "/wedding/vardan-karina",
        "/wedding/vardan-karina/",
        "/wedding/aper-mariam",
        "/wedding/aper-mariam/",
        "/wedding/armen-armine",
        "/wedding/armen-armine/",
        "/wedding/tigran-donara",
        "/wedding/tigran-donara/",
        "/wedding/narek-diana",
        "/wedding/narek-diana/",
        "/wedding/artsrun-anahit",
        "/wedding/artsrun-anahit/",
        "/wedding/artsrun-anahit/ru",
        "/wedding/artsrun-anahit/ru/",
        "/wedding/armen-anahit",
        "/wedding/armen-anahit/",
        "/wedding/toros-ada",
        "/wedding/toros-ada/",
        "/wedding/gor-lilit",
        "/wedding/gor-lilit/",
        "/wedding/gor-lilit/am",
        "/wedding/gor-lilit/am/",
        "/mkrtutyun/voskanyanner",
        "/mkrtutyun/voskanyanner/",
        "/wedding/gagik-ani",
        "/wedding/gagik-ani/",
        "/wedding/gegham-eleonora",
        "/wedding/gegham-eleonora/",
        "/wedding/gagik-mery",
        "/wedding/gagik-mery/",
        "/wedding/levon-anna",
        "/wedding/levon-anna/",
        "/wedding/serob-naira",
        "/wedding/serob-naira/",
        "/wedding/serob-naira/en",
        "/wedding/serob-naira/en/",
        "/wedding/gor-lusine",
        "/wedding/gor-lusine/",
        "/wedding/gor-lusine/ru",
        "/wedding/gor-lusine/ru/",
        "/wedding/robert-liana",
        "/wedding/robert-liana/",
        "/wedding/aram-ani",
        "/wedding/aram-ani/",
        "/wedding/aram-armine",
        "/wedding/aram-armine/",
        "/wedding/vahan-tina",
        "/wedding/vahan-tina/",
        "/event/ashas-corner",
        "/event/ashas-corner/",
        "/wedding/sargis-knarik",
        "/wedding/sargis-knarik/",
        "/wedding/ashot-armine",
        "/wedding/ashot-armine/",
        "/wedding/hamlet-shushan",
        "/wedding/hamlet-shushan/",
        "/wedding/sargis-kristine",
        "/wedding/sargis-kristine/",
        "/engagement/vahan-nona",
        "/engagement/vahan-nona/",
        "/birthday/angela",
        "/birthday/angela/",
        "/wedding/gor-nina",
        "/wedding/gor-nina/",
        "/wedding/mitya-lusine",
        "/wedding/mitya-lusine/",
        "/wedding/arman-mane",
        "/wedding/arman-mane/",
        "/wedding/vardan-mariam",
        "/wedding/vardan-mariam/",
        "/wedding/david-maga",
        "/wedding/david-maga/",
        "/wedding/hakob-mane",
        "/wedding/hakob-mane/",
        "/wedding/hayk-milena",
        "/wedding/hayk-milena/",
        "/wedding/artash-ani",
        "/wedding/artash-ani/",
        "/wedding/suren-ev-marina",
        "/wedding/suren-ev-marina/",
        "/wedding/nelson-elen",
        "/wedding/nelson-elen/",
        "/wedding/vigen-maga",
        "/wedding/vigen-maga/",      
        "/wedding/khachik-arpi",      
        "/wedding/khachik-arpi/",      
        "/mkrtutyun/hayk-david-astghik",
        "/mkrtutyun/hayk-david-astghik/", 
        "/wedding/henrikh-inna", 
        "/wedding/henrikh-inna/",
        "/wedding/arman-ev-mane",
        "/wedding/arman-ev-mane/",
        "/wedding/rafael-silva",
        "/wedding/rafael-silva/",
        "/wedding/vardan-lilit",
        "/wedding/vardan-lilit/",
        "/wedding/arshak-mariam",
        "/wedding/arshak-mariam/",
        "/wedding/roman-milena",
        "/wedding/roman-milena/",
        "/engagement/edgar-nona",
        "/engagement/edgar-nona/",
        "/engagement/edgar-nona/ru",
        "/engagement/edgar-nona/ru/",
        "/wedding/demo171",
        "/wedding/demo171/",
        "/wedding/demo172",
        "/wedding/demo172/",
        "/wedding/gurgen-marine",
        "/wedding/gurgen-marine/",
        "/wedding/demo174",
        "/wedding/demo174/",
    ];

    const routesToForceAmLang = [
        "/wedding/harutyun-amalya",
        "/templates/1001",
        "/templates/1002",
        "/templates/1003",
        "/templates/1008",
        "/templates/1011",
        "/templates/1012",
        "/wedding/shavarsh-aida",
        "/wedding/shavarsh-aida/",
        "/wedding/sargis-ev-piruz",
        "/wedding/sargis-ev-piruz/",
        "/wedding/sergey-mariam",
        "/wedding/sergey-mariam/",
        "/wedding/rafo-syuzi",
        "/wedding/rafo-syuzi/",
        "/wedding/tigran-anahit",
        "/wedding/tigran-anahit/",
        "/wedding/karen-sirusho",
        "/wedding/karen-sirusho/",
        "/wedding/tigran-sona",
        "/wedding/tigran-sona/",
        "/wedding/hrach-ev-ani",
        "/wedding/hrach-ev-ani/",
        "/wedding/samvel-mery",
        "/wedding/samvel-mery/",
        "/wedding/andranik-elina",
        "/wedding/andranik-elina/",
        "/mkrtutyun/emily",
        "/mkrtutyun/emily/",
        "/wedding/qajo-anjela",
        "/wedding/qajo-anjela/",
        "/mkrtutyun-taredardz/harutyunyanner",
        "/mkrtutyun-taredardz/harutyunyanner/",
        "/wedding/smbat-silvi",
        "/wedding/smbat-silvi/"
    ];

    useEffect(() => {
        const shouldHideNavbarAndFooter = hiddenRoutes.includes(location.pathname);
        setShowNavbarAndFooter(!shouldHideNavbarAndFooter);

        // Override language specifically for certain routes
        if (routesToForceAmLang.includes(location.pathname)) {
            i18n.changeLanguage("am"); // Force language to "am" for these routes
            localStorage.setItem('i18nextLngMainSite', "am"); // Update local storage to reflect language change
        }
    }, [location]);

    return (
        <I18nextProvider i18n={i18n}>
            <HelmetProvider>
                <div>
                    {showNavbarAndFooter && <ToTopButton />}
                    {showNavbarAndFooter && <Navbar />}
                    <PageRoutes />
                    {showNavbarAndFooter && <Footer />}
                </div>
            </HelmetProvider>
        </I18nextProvider>

    );
}

export default App;
